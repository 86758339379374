import { TextField } from '@mui/material'
import React, { useCallback, useState } from 'react'

type InputProps = {
	label: string
	type?: string
	multiline?: boolean
	name?: string
}

const CustomInput = ({ label, name, type = 'text', multiline = false }: InputProps) => {
	const [value, setValue] = useState<string>('')

	const textFieldSX = {
		'&.MuiTextField-root': {
			background: '#ECFFFA',
			borderRadius: multiline ? '32px' : '100px',
			width: 'calc(50% - 17px)',

			'& > *': {
				borderRadius: multiline ? '32px' : '100px',
			},
			'.MuiFormLabel-root, .MuiInputBase-input': {
				fontFamily: 'Degular',
				fontSize: '20px',
				fontWeight: 555,
				lineHeight: '24px',
				letterSpacing: '0.02em',
			},
			'.MuiInputBase-input': {
				padding: '20px 35px',
			},
			'.MuiFormLabel-root': {
				transform: 'translate(35px, 23px) scale(1)',
				color: 'rgba(15, 25, 54, 0.5)',
			},
			'.MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled': {
				color: '#00C495',
				top: '-30px',
				transform: 'none',
			},
			'.MuiInputBase-root': {
				fieldset: {
					top: 0,
					'& > legend': {
						display: 'none',
					},
				},
			},
			'.MuiInputBase-root.Mui-focused': {
				fieldset: {
					border: '2px solid #00C495',
					borderRadius: multiline ? '32px' : '100px',
				},
			},
			'&:hover': {
				fieldset: {
					border: '2px solid #00C495',
					borderWidth: '2px!important',
				},
			},
		},
	}

	const handleChangeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value)
	}, [])

	return (
		<TextField
			multiline={multiline}
			rows={multiline ? 5 : 1}
			type={type}
			label={label}
			name={name}
			variant="outlined"
			value={value}
			onChange={handleChangeValue}
			sx={textFieldSX}
		/>
	)
}

export default CustomInput
