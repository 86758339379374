export enum PATHS {
	ABOUT_US = '/about-us',
	CAREERS = '/careers',
	COMING_SOON = '/coming-soon',
	CONTACT = '/contact',
	COLLABORATION_PLATFORM = '/collaboration-platform',
	CUSTOM_COLLABORATION_SOLUTIONS = '/custom-collaboration-colutions',
	DEMO = '/demo',
	HOME = '/',
	HOW_IT_WORKS = '/how-it-works',
	PRIVACY_ENHANCING_TECHNOLOGIES = '/privacy-enhancing-technologies',
	SPECIFIC_USE_CASE = '/specific-use-case',
	RESOURCES = '/resources',
	BLOG = '/blog',
	FAQ = '/faq',
	INDUSTRY_OVERVIEW = '/industry-overview',
	USE_CASES = '/use-cases',
	HEALTHCARE = '/healthcare',
	FINANCE = '/finance',
	MANUFACTURING = '/manufacturing',
	LINKEDIN_COMPANY = 'https://www.linkedin.com/company/xtendr',
	LINKEDIN_JOBS = 'https://www.linkedin.com/company/xtendr/jobs',
	DEMO_LINK = 'https://platform.demo.xtendr.io/login',
	EMAIL_LINK = 'https://europe-central2-developmenttools-221923.cloudfunctions.net/website-email',
	BLOG1_ROUTE = '/real-world-uses-homomorphic-encryption',
	BLOG2_ROUTE = '/non-interactive-threshold-hashing-protocol',
	BLOG3_ROUTE = '/ai-model-training-privacy-enhancing-technologies',
	BLOG4_ROUTE = '/multiparty-commutative-hashing-protocol',
	BLOG5_ROUTE = '/real-world-uses-collaborative-data-sharing',
}
