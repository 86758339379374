import { useCallback } from 'react'

import { PATHS } from '../../src/utils/paths'

interface EmailData {
	email: string
	message?: string
	firstName?: string
	lastName?: string
	company?: string
	industry?: string
	role?: string
}

export const useEmailSubmit = () => {
	const handleSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>): Promise<{ success: boolean; message: string }> => {
			event.preventDefault()

			const formData = new FormData(event.currentTarget)
			const getFormDataValue = <T>(key: string): T | undefined => {
				const value = formData.get(key)
				return value ? (value as T) : undefined
			}

			const email = getFormDataValue<string>('email')
			const message = getFormDataValue<string>('message')
			const firstName = getFormDataValue<string>('firstName')
			const lastName = getFormDataValue<string>('lastName')
			const company = getFormDataValue<string>('company')
			const industry = getFormDataValue<string>('industry')
			const role = getFormDataValue<string>('role')

			if (!email) {
				return { success: false, message: 'A valid email is required' }
			}

			const body: EmailData = { email }

			if (message) body.message = message
			if (firstName) body.firstName = firstName
			if (lastName) body.lastName = lastName
			if (company) body.company = company
			if (industry) body.industry = industry
			if (role) body.role = role
			try {
				const response = await fetch(PATHS.EMAIL_LINK, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(body),
				})

				if (response.ok) {
					return {
						success: true,
						message: 'Your email has been sent successfully',
					}
				} else {
					const errorData = await response.json()
					return {
						success: false,
						message: `Failed to send email: form: ${response.status} - ${errorData.message}`,
					}
				}
			} catch {
				return {
					success: false,
					message: 'Error: Your email could not be sent successfully',
				}
			}
		},
		[]
	)

	return handleSubmit
}
