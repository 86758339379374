import { Navigate, Route, Routes } from 'react-router-dom'

import '../../styles/_header.scss'
import { PATHS } from '../../utils/paths'
import Blog1Route from '../Blog/blog1/Blog1Route'
import Blog2Route from '../Blog/blog2/Blog2Route'
import Blog3Route from '../Blog/blog3/Blog3Route'
import Blog4Route from '../Blog/blog4/Blog4Route'
import Blog5Route from '../Blog/blog5/Blog5Route'
import ResourcesRoute from '../Resources/ResourcesRoute'
import AboutUsRoute from '../aboutUs/AboutUsRoute'
import CareersRoute from '../careers/CareersRoute'
import CollaborationPlatformRoute from '../collaborationPlatform/CollaborationPlatformRoute'
import ComingSoonRoute from '../comingSoon/ComingSoonRoute'
import ContactRoute from '../contact/ContactRoute'
import CustomCollaborationSolutionsRoute from '../customCollaborationSolutions/CustomCollaborationSolutionsRoute'
import DemoRoute from '../demo/DemoRoute'
import FaqRoute from '../faq/FaqRoute'
import HomepageRoute from '../homepage/HomepageRoute'
import HowItWorksRoute from '../howItWorks/HowItWorksRoute'
import PageTransition from '../pageTransition/PageTranstion'
import PrivacyEnhancingTechnologyRoute from '../privacyEnhancingTechnology/PrivacyEnhancingTechnologyRoute'
import SpecificUseCaseRoute from '../specificUseCase/SpecificUseCaseRoute'
import { financeUseCase, healthcareUseCase, manufucturingCase } from '../specificUseCase/useCaseTexts'
import IndustryOverviewRoute from '../useCases/IndustryOverviewRoute'
import { financeOverviewData, healthOverviewData, manufacturingOverviewData } from '../useCases/IndustryText'

const RenderRoutes: React.FC = () => (
	<PageTransition>
		<Routes>
			<Route path={PATHS.HOME} element={<HomepageRoute />} />
			<Route path={PATHS.FAQ} element={<FaqRoute />} />
			<Route path={PATHS.CAREERS} element={<CareersRoute />} />
			<Route path={PATHS.COMING_SOON} element={<ComingSoonRoute />} />
			<Route path={PATHS.ABOUT_US} element={<AboutUsRoute />} />
			<Route path={PATHS.CONTACT} element={<ContactRoute />} />
			<Route path={PATHS.COLLABORATION_PLATFORM} element={<CollaborationPlatformRoute />} />
			<Route path={PATHS.CUSTOM_COLLABORATION_SOLUTIONS} element={<CustomCollaborationSolutionsRoute />} />
			<Route path={PATHS.DEMO} element={<DemoRoute />} />
			<Route path={PATHS.HOW_IT_WORKS} element={<HowItWorksRoute />} />
			<Route path={PATHS.PRIVACY_ENHANCING_TECHNOLOGIES} element={<PrivacyEnhancingTechnologyRoute />} />
			<Route path={PATHS.USE_CASES}>
				<Route
					path={`${PATHS.USE_CASES}${PATHS.HEALTHCARE}`}
					element={<SpecificUseCaseRoute usecase={healthcareUseCase} />}
				/>
				<Route
					path={`${PATHS.USE_CASES}${PATHS.FINANCE}`}
					element={<SpecificUseCaseRoute usecase={financeUseCase} />}
				/>
				<Route
					path={`${PATHS.USE_CASES}${PATHS.MANUFACTURING}`}
					element={<SpecificUseCaseRoute usecase={manufucturingCase} />}
				/>
			</Route>
			<Route path={PATHS.RESOURCES} element={<ResourcesRoute />} />
			<Route path={PATHS.BLOG}>
				<Route path={`${PATHS.BLOG}${PATHS.BLOG1_ROUTE}`} element={<Blog1Route />} />
				<Route path={`${PATHS.BLOG}${PATHS.BLOG2_ROUTE}`} element={<Blog2Route />} />
				<Route path={`${PATHS.BLOG}${PATHS.BLOG3_ROUTE}`} element={<Blog3Route />} />
				<Route path={`${PATHS.BLOG}${PATHS.BLOG4_ROUTE}`} element={<Blog4Route />} />
				<Route path={`${PATHS.BLOG}${PATHS.BLOG5_ROUTE}`} element={<Blog5Route />} />
			</Route>
			<Route path={PATHS.INDUSTRY_OVERVIEW}>
				<Route
					path={`${PATHS.INDUSTRY_OVERVIEW}${PATHS.HEALTHCARE}`}
					element={<IndustryOverviewRoute industryOverview={healthOverviewData} />}
				/>
				<Route
					path={`${PATHS.INDUSTRY_OVERVIEW}${PATHS.FINANCE}`}
					element={<IndustryOverviewRoute industryOverview={financeOverviewData} />}
				/>
				<Route
					path={`${PATHS.INDUSTRY_OVERVIEW}${PATHS.MANUFACTURING}`}
					element={<IndustryOverviewRoute industryOverview={manufacturingOverviewData} />}
				/>
			</Route>
			<Route path={'*'} element={<Navigate to={PATHS.HOME} />} />
		</Routes>
	</PageTransition>
)

export default RenderRoutes
