import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useCallback, useState } from 'react'

import { ReactComponent as ArrowDropdownIcon } from '../../../resources/img/arrowDropdown.svg'

type MenuItemType = {
	value: string
	option: string
}

type SelectProps = {
	id: string
	label: string
	menuItems?: Array<MenuItemType>
	onChange: (e: SelectChangeEvent) => void
	name: string
}

const selectSX = {
	'&.MuiFormControl-root': {
		background: '#ECFFFA',
		borderRadius: '100px',
		width: 'calc(50% - 17px)',

		'& > *': {
			borderRadius: '100px',
		},
		'.MuiSelect-icon': {
			right: '32px',
			top: 'calc(50% - 4px)',
		},
		'.MuiFormLabel-root, .MuiInputBase-input': {
			fontFamily: 'Degular',
			fontSize: '20px',
			fontWeight: 555,
			lineHeight: '24px',
			letterSpacing: '0.02em',
		},
		'.MuiInputBase-input': {
			padding: '20px 35px',
		},
		'.MuiFormLabel-root': {
			transform: 'translate(35px, 23px) scale(1)',
			color: 'rgba(15, 25, 54, 0.5)',
		},
		'.MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled': {
			color: '#00C495',
			top: '-30px',
			transform: 'none',
		},
		'.MuiInputBase-root': {
			fieldset: {
				top: 0,
				'& > legend': {
					display: 'none',
				},
			},
		},
		'.MuiInputBase-root.Mui-focused': {
			fieldset: {
				border: '2px solid #00C495',
				borderRadius: '100px',
			},
		},
		'&:hover': {
			fieldset: {
				border: '2px solid #00C495',
				borderWidth: '2px!important',
			},
		},
	},
}

const CustomSelect = ({ label, menuItems, id, onChange, name }: SelectProps) => {
	const [value, setValue] = useState<string>('')

	const handleChangeValue = useCallback(
		(e: SelectChangeEvent) => {
			setValue(e.target.value)
			onChange(e)
		},
		[onChange]
	)

	return (
		<FormControl fullWidth sx={selectSX}>
			<InputLabel id={id}>{label}</InputLabel>
			<Select
				variant="outlined"
				labelId={id}
				value={value}
				label={label}
				onChange={handleChangeValue}
				IconComponent={ArrowDropdownIcon}
				name={name}
			>
				{menuItems?.map((item: MenuItemType, index: number) => (
					<MenuItem key={index} value={item.value}>
						{item.option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default CustomSelect
